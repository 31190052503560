import React, { Component } from 'react';
import { connect } from "react-redux";
import { Image, Row, Col, Media, Spinner, Button } from 'react-bootstrap';
import firebase from './Firebase';
import ReactHtmlParser from 'react-html-parser';
import { Table } from 'react-bootstrap';
import './Global.js';
import { AiFillApple } from 'react-icons/ai';
import { SiPlaystation4 } from 'react-icons/si';
import { FaXbox } from 'react-icons/fa';
import { RiComputerLine } from 'react-icons/ri';
import { Helmet } from 'react-helmet';
import { SiPlaystation5, SiTesla } from "react-icons/si";
import SideBar from './SideBar';
import Disqus from "disqus-react";
import { setCurrentArticle } from './actions';

const mapStateToProps = state => {
    return {
        username: state.username,
        isDarkMode: state.isDarkMode,
        avatarUrl: state.avatarUrl
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setCurrentArticle: userData => dispatch(setCurrentArticle(userData))
    };
}

class ReadMore extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            content: "",
            noteDate: "",
            intro: "",
            introUrl: "",
            avatarUrl: "",
            username: "",
            bio: "",
            rating: "",
            pros: "",
            cons: "",
            noteType: "",
            consoleId: "",
            noteId: "",
            notesList: [],
            isLoadingOtherNotes: [],
            isLoading: false,
            showTopBtn: false
        };

        window.scrollTo(0, 0);

        this.props.isDarkMode ?
            document.body.style.background = "black" : document.body.style.background = "white";
    }

    goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    componentWillMount() {
        this.setState({ isLoading: true });
        let self = this;
        let formattedTitleFromURL = this.props.match.params.title.replace(/-/g, ' ');

        firebase.database()
            .ref()
            .child('notes')
            .orderByChild('title')
            .equalTo(formattedTitleFromURL)
            .on("value", function (snapshot) {
                if (snapshot.val() != null) {

                    snapshot.forEach(function (data) {

                        self.setState({
                            noteId: data.key,
                            title: formattedTitleFromURL,
                            content: data.val().content,
                            noteDate: data.val().noteDate,
                            intro: data.val().intro,
                            introUrl: data.val().introUrl,
                            noteType: data.val().noteType,
                            consoleId: data.val().consoleId,
                            rating: data.val().rating,
                            pros: data.val().pros,
                            cons: data.val().cons,
                            isLoading: false
                        });

                        document.title = formattedTitleFromURL;
                        firebase.database().ref('/users/' + data.val().uid).once('value').then(function (userSnapshot) {
                            self.setState({
                                username: userSnapshot.val().username,
                                avatarUrl: userSnapshot.val().avatarUrl,
                                bio: userSnapshot.val().bio,
                            });
                        });

                        let articleData = {
                            currentArticleName: formattedTitleFromURL
                        };
                        self.props.setCurrentArticle({ articleData });
                    });
                }
            })
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                this.setState({ showTopBtn: true });

            } else {
                this.setState({ showTopBtn: false });
            }
        });
    }


    goToLink(link) {
        window.location = link;
    }

    getNoteTypeLabel() {
        switch (this.state.noteType) {
            case global.config.NoteTypes.Review:
                return "مُراجعة";
            case global.config.NoteTypes.Report:
                return "تقرير";
            case global.config.NoteTypes.News:
                return "خبر";
            case global.config.NoteTypes.Other:
                return "مقالات عامة";
            default:
                return;
        }
    }

    getConsoleBadge() {
        switch (this.state.consoleId) {
            case global.config.ConsoleNames.Ps4:
                return <SiPlaystation4 style={{ fontSize: "32", color: this.props.isDarkMode && "white" }} />;
            case global.config.ConsoleNames.XboxSeriesXS:
                return <FaXbox style={{ fontSize: "32", color: this.props.isDarkMode && "white" }} />;
            case global.config.ConsoleNames.Ps5:
                return <span style={{ display: 'block' }}><SiPlaystation5 style={{ fontSize: "32", color: this.props.isDarkMode && "white" }} /></span>;
            case global.config.ConsoleNames.Pc:
                return <RiComputerLine style={{ fontSize: "32", color: this.props.isDarkMode && "white" }} />;
            case global.config.ConsoleNames.Apple:
                return <span style={{ display: 'block' }}><AiFillApple style={{ fontSize: "24", color: "rgb(112,112,112)" }} /></span >;
            case global.config.ConsoleNames.Tesla:
                return <span style={{ display: 'block' }}><SiTesla style={{ fontSize: "24", color: "rgb(112,112,112)" }} /></span >;
            default:
                return;
        }
    }

    getLinkByNoteType(title, noteType) {
        switch (noteType) {
            case global.config.NoteTypes.Review:
                return (`/reviews/${title.replace(/\s+/g, '-')}`);
            case global.config.NoteTypes.Report:
                return (`/reports/${title.replace(/\s+/g, '-')}`);
            case global.config.NoteTypes.News:
                return (`/news/${title.replace(/\s+/g, '-')}`);
            default:
                return;
        }
    }

    render() {
        const disqusTheme = this.props.isDarkMode ? 'dark-mode' : 'light-mode';

        const disqusShortname = "soumarTechPark"
        const disqusConfig = {
            identifier: this.state.noteId,
            title: this.state.title,
            url: "https://www.soumar-techpark.com" + this.props.match.url
        }

        return (
            <div>
                <div className="top-to-btm">
                    {" "}
                    {this.state.showTopBtn && (
                        <i
                            className={`bi bi-arrow-up-circle-fill icon-position ${this.props.isDarkMode ? " icon-style-dark-mode" : " icon-style"}`}
                            onClick={this.goToTop}>
                        </i>
                    )}{" "}
                </div>
                <Helmet>
                    <meta property="og:url" content={"https://www.soumar-techpark.com" + this.props.match.url} />
                    <meta property="og:title" content={this.state.title} />
                    <meta property="og:description" content={this.state.intro} />
                    <meta property="og:image" content={this.state.introUrl} />
                    <meta property="og:image:width" content="500" />
                    <meta property="og:image:height" content="500" />
                    <meta name="og:type" content="website" />
                </Helmet>
                <Row>
                    <Col>
                        <div>
                            {this.state.isLoading &&
                                (<div style={{ textAlign: "center" }}>
                                    <Spinner animation="border"
                                        className={`spinner-style ${this.props.isDarkMode ? "spinner-style-dark-mode" : ""}`} size="sm" />
                                </div>)
                            }
                            {this.state.isLoading == false &&
                                < div className={`readmore ${this.props.isDarkMode ? "readmore-darkmode" : ""}`}>
                                    <div className="not-to-copy text-align-right">

                                        <p className={`title ${this.props.isDarkMode ? "title-darkmode" : ""}`}>
                                            {this.state.title}
                                        </p>

                                        <p className={`intro ${this.props.isDarkMode ? "intro-darkmode" : ""}`}>
                                            {this.state.intro}
                                        </p>

                                        <p style={{ textAlign: "center" }}>
                                            <Image src={this.state.introUrl} className="mb-4 intro-image" rounded></Image>
                                        </p>

                                        <div>
                                            <Media>
                                                <Media.Body style={{ fontWeight: "700" }} className="text-muted">
                                                    <Image src={this.state.avatarUrl} roundedCircle height="30" width="30" className='ml-2' />
                                                    {this.state.username} <span className='pr-2 pl-2'>|</span>
                                                    نُشر في {this.state.noteDate.substring(0, 10)}
                                                </Media.Body>
                                            </Media>
                                        </div>

                                        <hr className={`${this.props.isDarkMode ? "hr-dark-mode" : ""}`}></hr>


                                        <div className={`content ${this.props.isDarkMode ? "content-darkmode" : ""}`}>
                                            {ReactHtmlParser(this.state.content)}
                                        </div>
                                        {this.state.noteType === global.config.NoteTypes.Review ?
                                            (
                                                <div>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th className={`table-cons-pros-header ${this.props.isDarkMode ? "table-cons-pros-header-darkmode" : ""}`}>نقاط القوة</th>
                                                                <th className={`table-cons-pros-header ${this.props.isDarkMode ? "table-cons-pros-header-darkmode" : ""}`}>نقاط الضعف</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className={`pros ${this.props.isDarkMode ? "pros-darkmode" : ""}`}>
                                                                    {this.state.pros}
                                                                </td>
                                                                <td className={`cons ${this.props.isDarkMode ? "cons-darkmode" : ""}`}>
                                                                    {this.state.cons}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>

                                                    <hr className={`${this.props.isDarkMode ? "hr-dark-mode" : ""}`}></hr>

                                                    <div style={{ fontWeight: "bold", fontSize: "large", textAlign: "center" }} className={`element-light-mode ${this.props.isDarkMode ? "element-dark-mode" : ""}`} >
                                                        <div>
                                                            التقييم النهائي
                                                        </div>
                                                        <div>
                                                            <h1 style={{ fontSize: "80px", color: "red" }}>
                                                                {this.state.rating}
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div >
                                            ) : null
                                        }
                                    </div>
                                </div>
                            }
                            {!this.state.isLoading &&
                                < div >
                                    <Disqus.DiscussionEmbed
                                        shortname={disqusShortname}
                                        config={disqusConfig}
                                        className={disqusTheme}
                                    />
                                </div>
                            }
                        </div>

                        <div className="read-more-footer">
                            <SideBar />
                        </div>
                    </Col>
                </Row >

            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadMore);