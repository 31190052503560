import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";

const mapStateToProps = state => {
    return { isDarkMode: state.isDarkMode };
};

class Terms extends Component {
    constructor(props) {
        super(props);
        document.title = "شروط الخدمة";
        this.props.isDarkMode ?
            document.body.style.background = "black" : document.body.style.background = "white";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Row className={`${this.props.isDarkMode ? "content-darkmode" : "content"}`}>
                <Col>
                </Col>
                <Col dir="rtl"
                    style={{ textAlign: "center" }} xs={12} md={6}>
                    <p style={{ fontSize: "20px", color: "grey" }}><b>شروط الخدمة</b></p>

                    <p className={`fade-in site-title ${this.props.isDarkMode ? "site-title-darkmode" : ""}`} dir="ltr">
                        Soumar Tech Park
                    </p>
                    <br />
                    <div style={{ textAlign: "right" }}>
                        <b>
                            الالتزام بالأخلاق في كتابة الردود
                        </b>
                        <br />
                        يُرجى الالتزام بالاحترام والأدب في كتابة الردود.
                        هدفنا هُوَ خلقُ نقاشات هادفة تُضفي الفائدة على القارئ، وتُغني المحتوى العربي.
                        يجب على الجميع الالتزام بعدم كتابة تعليقات تحتوي على كلام سياسي، قبلي، عُنصري، طائفي.
                        يُمنع منعا باتًا نشر كلام يمسّ العرق أو الدين أو التوجهات الجنسيّة للأفراد مهما كانت. يُمنع أيضاً استعمال الكلام البذيء أو اهانة الآخرين أو جعل الموقع مكاناً للنقاشات خارج الموضوع.
                        يتحمّل كلّ صاحب تعليق مسؤوليته الكاملة في ما نشرهُ.
                        <br />
                        <br />
                        <b>
                            عدم النقل
                        </b>
                        <br />
                        يُمنع منعًا باتا نقل مُحتوى أيّ مقال في هذا الموقع سواءً بشكل جزئيّ أو كامل.
                        <br />
                        <br />
                        <b>
                            الدعاية والإعلان
                        </b>
                        <br />
                        يُمنع منعًا باتا وضع أيّ شكل من أشكال الدعاية في تعليقات الموقع.
                        <br />
                        <br />
                        <b>
                            التعصب
                        </b>
                        <br />
                        نتفهّم أنهُ لدى كلّ فرد منا ميولات وتفضيلات مختلفة، لكن بشرط أن لا يتطوّر الأمر إلى التعصب الذي يتمّ اظهارهُ في التعليقات أمام الجميع.بالإمكان مناقشة اختياراتنا لكن دونَ أن تتحوّل ساحة التعليقات إلى حلبة لنشر التعصب.
                        <br />
                        <br />
                        <b>
                            الحرق
                        </b>
                        <br />
                        يمُنع منعا باتا وضع ردود تهدف إلى حرق مُحتوى لعبة ما في مراجعة من أجل أن نفسد التجربة على اللاعبين الآخرين.
                        <br />
                        <br />
                        <b>
                            اللغة
                        </b>
                        <br />
                        موقع Soumar Tech Park هو موقع عربي، وهدفهُ تقديم الفائدة إلى جميع الناطقين بهذه اللغة، يجب على جميع التعليقات أن تكون باللغة العربية فقط.لكن لا مانع من استخدام الانجليزية في استعمال بعض الألفاظ حينما يتعذّر إيجاد مرادفها العربي.
                        <br />
                        <br />
                        <b>
                            سياسة الخصوصيّة
                        </b>
                        <br />
                        بالإمكان قراءة سياسة الخصوصيّة الخاصة بموقعنا من
                        <a href="/privacy"> هُنا</a>
                        .
                    </div>
                </Col>
                <Col>
                </Col>
            </Row >
        )
    }
}

export default connect(mapStateToProps)(Terms);