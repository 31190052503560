module.exports = global.config = {
    NoteTypes: {
        Review: 1,
        Report: 2,
        News: 3
    },
    UserTypes: {
        Admin: 1,
        User: 2
    },
    ConsoleNames: {
        Ps4: 1,
        XboxSeriesXS: 2,
        Ps5: 3,
        Pc: 4,
        Apple: 5,
        NoName: 6,
        Tesla: 7
    }
};