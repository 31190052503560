import { ADD_USER_DATA } from "../constants/action-types";
import { CLEAR_ALL_DATA } from "../constants/action-types";
import { CHANGE_USER_INFO } from "../constants/action-types";
import { SET_DARK_MODE } from "../constants/action-types";
import { SET_CURRENT_ARTICLE } from "../constants/action-types";

const initialState = {
    id: '',
    username: '',
    email: '',
    hasNewNotification: false,
    joinDate: '',
    bio: '',
    avatarUrl: '',
    currentTitle: '',
    currentContent: '',
    isDarkMode: false,
    currentArticleName: ''
};

function rootReducer(state = initialState, action) {
    if (action.type === ADD_USER_DATA) {
        return Object.assign({}, state, {
            id: action.payload.userData.id,
            username: action.payload.userData.username,
            email: action.payload.userData.email,
            hasNewNotification: action.payload.userData.hasNewNotification,
            joinDate: action.payload.userData.joinDate,
            bio: action.payload.userData.bio,
            avatarUrl: action.payload.userData.avatarUrl,
        });
    }

    if (action.type === CHANGE_USER_INFO) {
        return Object.assign({}, state, {
            bio: action.payload.userData.bio,
            avatarUrl: action.payload.userData.avatarUrl,
        });
    }

    if (action.type === SET_CURRENT_ARTICLE) {
        return Object.assign({}, state, {
            currentArticleName: action.payload.articleData.currentArticleName,
        });
    }

    if (action.type === CLEAR_ALL_DATA) {
        state = initialState;
    }

    if (action.type === SET_DARK_MODE) {
        return Object.assign({}, state, {
            isDarkMode: !state.isDarkMode
        });
    }

    return state;
}

export default rootReducer;