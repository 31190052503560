import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap';
import { AiOutlineMail } from 'react-icons/ai';

const NotFound = ({ username, isDarkMode }) => {
    useEffect(() => {
        // Set the page title
        document.title = "404";

        // Scroll to top on mount
        window.scrollTo(0, 0);
    }, [isDarkMode]);

    return <div className={`${isDarkMode ? "not-found-content-dark" : "not-found-content"}`}>
        <p>
            <i class="bi bi-emoji-dizzy" style={{ fontSize: "50px" }}></i>
        </p>
        <h2>
            404
        </h2>
        <h4>
            عفوا! لا يمكن العثور على هذه الصفحة.
        </h4>
    </div>
};

const mapStateToProps = state => ({
    isDarkMode: state.isDarkMode
});

export default connect(mapStateToProps)(NotFound);