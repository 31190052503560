import { ADD_USER_DATA, SET_CURRENT_ARTICLE } from "../constants/action-types";
import { CLEAR_ALL_DATA } from "../constants/action-types";
import { CHANGE_USER_INFO } from "../constants/action-types";
import { SET_DARK_MODE } from "../constants/action-types";

export function addUserData(payload) {
    return { type: ADD_USER_DATA, payload }
};

export function clearAllData(payload) {
    return { type: CLEAR_ALL_DATA, payload }
};

export function changeUserInfo(payload) {
    return { type: CHANGE_USER_INFO, payload }
};

export function setDarkMode(payload) {
    return { type: SET_DARK_MODE, payload }
};

export function setCurrentArticle(payload) {
    return { type: SET_CURRENT_ARTICLE, payload }
};