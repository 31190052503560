import React, { Component } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactHtmlParser from 'react-html-parser';
import { Row, Col, Button, Form, ProgressBar } from 'react-bootstrap';
import { connect } from "react-redux";
import firebase from './Firebase';
import embed from "embed-video";
import FileUploader from "react-firebase-file-uploader";
import './Global.js';

const mapStateToProps = state => {
    return { id: state.id, username: state.username, isDarkMode: state.isDarkMode };
};

class AddNote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            name: "",
            title: "",
            intro: "",
            rating: "",
            isUploading: false,
            progress: 0,
            introUrl: "",
            pros: "",
            cons: "",
            selectedNoteType: global.config.NoteTypes.News,
            selectedConsole: global.config.ConsoleNames.NoName,
        }

        document.title = "اضافة";
        document.body.style.backgroundColor = "white";

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeConsoleSelect = this.handleChangeConsoleSelect.bind(this);
        this.addNote = this.addNote.bind(this);
        this.props.isDarkMode ?
            document.body.style.background = "black" : document.body.style.background = "white";
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = filename => {
        this.setState({ progress: 100, isUploading: false });
        firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(url => this.setState({ introUrl: url }))
    };

    addNote() {
        let today = new Date();

        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        today = dd + '-' + mm + '-' + yyyy;

        let noteData = {
            uid: this.props.id,
            title: this.state.title,
            content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
            intro: this.state.intro,
            noteDate: today,
            introUrl: this.state.introUrl,
            noteType: this.state.selectedNoteType,
            consoleId: this.state.selectedConsole,
            username: this.props.username,
            rating: this.state.rating,
            pros: this.state.pros,
            cons: this.state.cons
        };

        // Get a key for a new note
        var newNoteKey = firebase.database().ref().child('notes').push().key;

        var updates = {};
        updates['/notes/' + newNoteKey] = noteData;
        firebase.database().ref().update(updates);

        this.props.history.push(`/article/${this.state.title}`);
    }

    onEditorStateChange: Function = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeSelect(event) {
        this.setState({ selectedNoteType: +event.target.value });
    }

    handleChangeConsoleSelect(event) {
        this.setState({ selectedConsole: +event.target.value });
    }

    render() {
        const { editorState } = this.state;
        return (

            <Row className="text-align-right">
                <Col> </Col>

                <Col xs={12} lg={6}>

                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <h4> صورة الصفحة الرئيسيّة</h4>

                        {this.state.isUploading && <div>
                            Progress
                            <ProgressBar now={this.state.progress} label={`${this.state.progress}%`} />
                        </div>}
                        {this.state.introUrl && <img height='auto' width='100%' src={this.state.introUrl} alt="" />}

                        <FileUploader
                            accept="image/*"
                            name="into"
                            randomizeFilename
                            storageRef={firebase.storage().ref("images")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                        />
                    </div>

                    <div>
                        <Form.Group controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="العنوان"
                                name="title"
                                value={this.state.title}
                                onChange={this.handleChange} />
                            <Form.Text className="text-muted">
                                قم بإضافة عنوان جيد كي تجلب اهتمام الزائرين
                            </Form.Text>
                        </Form.Group>
                    </div>

                    <div>
                        <Form.Group controlId="formBasicIntro">
                            <Form.Control type="text" placeholder="المقدمة"
                                name="intro"
                                value={this.state.intro}
                                onChange={this.handleChange} />
                            <Form.Text className="text-muted">
                                مُقدمة توضع في الصفحة الرئيسيّة للموقع
                            </Form.Text>
                        </Form.Group>
                    </div>

                    <div>
                        <Form>
                            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                                <Form.Label>نوع التدوينة</Form.Label>
                                <Form.Control as="select" size="sm" custom value={this.state.selectedNoteType} onChange={this.handleChangeSelect}>
                                    <option value={global.config.NoteTypes.Review}>مُراجعة</option>
                                    <option value={global.config.NoteTypes.Report}>تقرير</option>
                                    <option value={global.config.NoteTypes.News}>خبر</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                                <Form.Label>الجهاز</Form.Label>
                                <Form.Control as="select" size="sm" custom value={this.state.selectedConsole} onChange={this.handleChangeConsoleSelect}>
                                    <option value={global.config.ConsoleNames.Ps4}>PS4</option>
                                    <option value={global.config.ConsoleNames.XboxSeriesXS}>XBOX</option>
                                    <option value={global.config.ConsoleNames.Ps5}>PS5</option>
                                    <option value={global.config.ConsoleNames.Pc}>PC</option>
                                    <option value={global.config.ConsoleNames.Apple}>Apple</option>
                                    <option value={global.config.ConsoleNames.Tesla}>Tesla</option>
                                    <option value={global.config.ConsoleNames.NoName}>غير محدد</option>
                                </Form.Control>
                            </Form.Group>

                        </Form>
                    </div>

                    <div>
                        <h4>إضافة تدوينة</h4>

                        <div style={{ direction: "ltr" }}>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="editor-class"
                                onEditorStateChange={this.onEditorStateChange}
                                placeholder="المحتوى"
                                toolbar={{
                                    link: {
                                        linkCallback: params => ({ ...params })
                                    },
                                    embedded: {
                                        embedCallback: link => {
                                            const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                            return (detectedSrc && detectedSrc[1]) || link;
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <br />

                    <div>
                        <Form.Group controlId="formBasicPros">
                            <Form.Control as="textarea" rows={3} placeholder="الايجابيات"
                                name="pros"
                                value={this.state.pros}
                                onChange={this.handleChange} />
                        </Form.Group>
                    </div>

                    <div>
                        <Form.Group controlId="formBasicCons">
                            <Form.Control as="textarea" rows={3} placeholder="السلبيات"
                                name="cons"
                                value={this.state.cons}
                                onChange={this.handleChange} />
                        </Form.Group>
                    </div>

                    <div>
                        <Form.Group controlId="formBasicRating">
                            <Form.Control type="text" placeholder="التقييم النهائي"
                                name="rating"
                                value={this.state.rating}
                                onChange={this.handleChange} />
                        </Form.Group>
                    </div>

                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Button onClick={this.addNote}> أضف التدوينة</Button>
                    </div>

                </Col>

                <Col> </Col>
            </Row >
        );
    }
}

export default connect(mapStateToProps)(AddNote);