import React, { Component } from 'react';
import firebase from './Firebase';
import { connect } from "react-redux";
import { Button, Form, Row, Col } from 'react-bootstrap';

import { addUserData } from "./actions/index";

function mapDispatchToProps(dispatch) {
    return {
        addUserData: userData => dispatch(addUserData(userData))
    };
}

class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            error: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.signUp = this.signUp.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    signUp(e) {
        let self = this;
        let today = new Date();

        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        today = dd + '-' + mm + '-' + yyyy;

        e.preventDefault();

        firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
            .then(function (data) {
                firebase.database().ref('users/' + data.user.uid).set({
                    username: self.state.username,
                    email: self.state.email,
                    bio: '',
                    joinDate: today,
                    hasNewNotification: false,
                    avatarUrl: "http://1.gravatar.com/avatar/1e1dae20137bf0d7c1be5fea28672af9?s=280&d=http%3A%2F%2F1.gravatar.com%2Favatar%2Fad516503a11cd5ca435acc9bb6523536%3Fs%3D280&r=G",
                });

                let userData = {
                    id: data.user.uid,
                    username: self.state.username,
                    email: self.state.email,
                    bio: '',
                    joinDate: today,
                    hasNewNotification: false,
                    avatarUrl: "http://1.gravatar.com/avatar/1e1dae20137bf0d7c1be5fea28672af9?s=280&d=http%3A%2F%2F1.gravatar.com%2Favatar%2Fad516503a11cd5ca435acc9bb6523536%3Fs%3D280&r=G",
                };
                self.props.addUserData({ userData });
            })
            .catch((error) => {
                this.setState({ error: error });
            });
    }

    render() {
        return (
            <Row>
                <Col>
                </Col>

                <Col xs="12" lg="3">
                    <Form onSubmit={this.signUp} className="text-align-right">
                        <h3>التسجيل</h3>

                        <Form.Group controlId="formBasicUserName">
                            <Form.Control type="text" placeholder="اسم المستخدم"
                                name="username"
                                value={this.state.username}
                                onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="البريد الإلكتروني"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange} />
                            <Form.Text className="text-muted">
                                لن تتمّ مشاركة بريدك الإلكتروني مع أي طرف آخر.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Control type="password" placeholder="كلمة السر"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange} />
                        </Form.Group>

                        <div style={{ color: 'red', marginBottom: '20px' }}>
                            {this.state.error ? (
                                <div>
                                    {this.state.error.message}
                                </div>
                            ) : null}
                        </div>

                        <Button variant="primary" type="submit">
                            تسجيل الدخول
                        </Button>
                    </Form>
                </Col>

                <Col>
                </Col>
            </Row>
        );
    }
}

const SignUp = connect(
    null,
    mapDispatchToProps
)(SignUpForm);

export default SignUp;