import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Form, Row, Col } from 'react-bootstrap';
import firebase from './Firebase';
import { addUserData } from "./actions/index";

function mapDispatchToProps(dispatch) {
    return {
        addUserData: userData => dispatch(addUserData(userData))
    };
}

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: null,
        }

        this.handleChange = this.handleChange.bind(this);
        this.login = this.login.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    login(e) {
        let self = this;
        e.preventDefault();
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(function (data) {
                firebase.database().ref('/users/' + data.user.uid).once('value').then(function (snapshot) {
                    let userData = {
                        id: data.user.uid,
                        username: snapshot.val().username,
                        avatarUrl: snapshot.val().avatarUrl,
                        hasNewNotification: snapshot.val().hasNewNotification,
                        joinDate: snapshot.val().joinDate,
                        email: snapshot.val().email,
                        bio: snapshot.val().bio,
                    };
                    self.props.addUserData({ userData });
                })
            })
            .catch((error) => {
                this.setState({ error: error });
            });
    }

    render() {
        return (
            <Row>
                <Col>
                </Col>
                <Col xs="12" lg="3">
                    <Form onSubmit={this.login} >
                        <h3 style={{ textAlign: "right" }}>تسجيل الدخول</h3>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="البريد الإلكتروني"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Control type="password" placeholder="كلمة السر"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange} />
                        </Form.Group>

                        <div style={{ color: 'red', marginBottom: '20px' }}>
                            {this.state.error ? (
                                <div dir='ltr'>
                                    {this.state.error.message}
                                </div>
                            ) : null}
                        </div>

                        <Button variant="primary" type="submit" style={{ width: "100%", backgroundColor: "#C13584", borderColor: 'white' }}>
                            تسجيل الدخول
                        </Button>
                    </Form>

                    <div style={{ color: "red", textAlign: "right" }} >
                        خاصيّة التسجيل غير متوفرة حاليا.
                    </div>
                </Col>
                <Col>
                </Col>
            </Row >
        )
    }
}

const Login = connect(
    null,
    mapDispatchToProps
)(LoginForm);

export default Login;