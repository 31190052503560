import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Form, Image, Modal, ProgressBar } from 'react-bootstrap';
import firebase from './Firebase';
import { changeUserInfo } from "./actions/index";
import FileUploader from "react-firebase-file-uploader";

const mapStateToProps = state => {
    return { username: state.username, avatarUrl: state.avatarUrl, bio: state.bio, id: state.id, joinDate: state.joinDate };
};

function mapDispatchToProps(dispatch) {
    return {
        changeUserInfo: userData => dispatch(changeUserInfo(userData))
    };
}

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowProfileEditModal: false,
            bio: this.props.bio,
            isUploading: false,
            progress: 0,
            avatarUrl: this.props.avatarUrl
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.save = this.save.bind(this);
        this.saveToStore = this.saveToStore.bind(this);
    }

    handleShow() {
        this.setState({ isShowProfileEditModal: true });
    }

    handleClose() {
        this.setState({ isShowProfileEditModal: false });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // upload functions
    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = filename => {
        let self = this;
        self.setState({ progress: 100, isUploading: false });
        firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(
                url => {
                    self.setState({ avatarUrl: url });
                }
            )
    };

    save() {
        let self = this;
        let updateUserBio = {};
        let updateUserAvatar = {};

        updateUserBio['/users/' + self.props.id + "/bio"] = self.state.bio;
        updateUserAvatar['/users/' + self.props.id + "/avatarUrl"] = self.state.avatarUrl;
        firebase.database().ref().update(updateUserBio);
        firebase.database().ref().update(updateUserAvatar);
        this.saveToStore();
    }

    saveToStore() {
        let userData = {
            avatarUrl: this.state.avatarUrl,
            bio: this.state.bio,
        };
        this.props.changeUserInfo({ userData });
    }

    render() {
        return (
            <div style={{ textAlign: "center" }} xs={12} md={4}>
                <Image
                    src={this.props.avatarUrl}
                    style={{ maxWidth: "150px" }}
                    className="profile-image" roundedCircle />
                <h5
                    style={{ fontWeight: "bold" }}>
                    {this.props.username}
                </h5>

                <h6 style={{ direction: "ltr" }}> {this.props.joinDate.substring(0, 10)} إنضم في</h6>

                <h6> {this.props.bio} </h6>

                <Button variant="outline-success" size="sm" onClick={this.handleShow}>
                    تعديل الملف الشخصيّ
                </Button>

                <Modal show={this.state.isShowProfileEditModal === true} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{ direction: "ltr" }}>
                        <Modal.Title>تعديل الملف الشخصيّ</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Form.Group controlId="formBasicTitle">
                                <Form.Control type="text" placeholder="النبذة التعريفيّة"
                                    name="bio"
                                    value={this.state.bio}
                                    onChange={this.handleChange} />
                            </Form.Group>
                        </div>

                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <h4> صورة الملف الشخصيّ</h4>

                            {this.state.isUploading && <div>
                                Progress
                                <ProgressBar now={this.state.progress} label={`${this.state.progress}%`} />
                            </div>}
                            {this.state.avatarUrl && <img height='auto' width='100%' src={this.state.avatarUrl} alt="" />}

                            <FileUploader
                                accept="image/*"
                                name="profile"
                                randomizeFilename
                                storageRef={firebase.storage().ref("images")}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                            />
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="success" onClick={this.save}>
                            حفظ
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);