import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, Button, Row, Col, Image, Badge } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const mapStateToProps = state => {
    return { username: state.username, avatarUrl: state.avatarUrl, bio: state.bio, id: state.id, joinDate: state.joinDate, isDarkMode: state.isDarkMode };
};

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //
        };
        this.goToLink = this.goToLink.bind(this);
        this.props.isDarkMode ?
            document.body.style.background = "black" : document.body.style.background = "white";
    }

    getNoteTypeLabel(noteType) {
        switch (noteType) {
            case global.config.NoteTypes.Review:
                return "مُراجعة";
            case global.config.NoteTypes.Report:
                return "تقرير";
            case global.config.NoteTypes.News:
                return "خبر";
            default:
                return "مقالات عامة"
        }
    }

    goToLink(link) {
        window.location = link;
    }

    getLinkByNoteType(title, noteType) {
        switch (noteType) {
            case global.config.NoteTypes.Review:
                return (`/reviews/${title.replace(/\s+/g, '-')}`);
            case global.config.NoteTypes.Report:
                return (`/reports/${title.replace(/\s+/g, '-')}`);
            case global.config.NoteTypes.News:
                return (`/news/${title.replace(/\s+/g, '-')}`);
            default:
                return;
        }
    }

    render() {
        return (
            <div className="not-to-copy">
                <Row xs={12}>
                    {this.props.notesList.map((note, index) => (
                        <Col key={note.key} xs={12} md={12}>
                            <div className="d-flex flex-column">
                                <div className="d-md-flex">
                                    <div className="flex-shrink-0 pl-5">
                                        <Image src={note.introUrl} rounded className='custom-image' />
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="fw-bold mb-2">
                                            <Link to={this.getLinkByNoteType(note.title, note.noteType)} className={`${this.props.isDarkMode ? "title-style-dark-mode" : "title-style"}`}>
                                                <b>{note.title}</b>
                                            </Link>
                                        </div>

                                        <div className="mb-3 text-muted" style={{ fontSize: "14px", fontWeight: "700" }}>
                                            {note.noteDate.substring(0, 10)}
                                        </div>

                                        <div className={`mb-3 ${this.props.isDarkMode ? "notes-intro-dark-mode" : "notes-intro"}`}>
                                            {note.intro}
                                        </div>

                                        {this.props.isShowType &&
                                            <div className="d-flex flex-row">
                                                <Badge variant="dark" pill>
                                                    {this.getNoteTypeLabel(note.noteType)}
                                                </Badge>
                                            </div>
                                        }

                                        {this.props.auth ? (
                                            <div className='mt-3 mb-3'>
                                                <Link to={`/editnote/${note.key}`}>
                                                    <FiEdit />
                                                </Link>
                                                {/* <Button variant="success"
                                                    style={{ width: "100%" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.goToLink(`/editnote/${note.key}`);
                                                    }}>
                                                    <FiEdit />

                                                </Button> */}
                                            </div>)
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr className={`${this.props.isDarkMode ? "hr-dark-mode" : ""}`}></hr>
                        </Col>
                    ))}
                </Row>
            </div >
        )
    }
}

export default connect(mapStateToProps)(Profile);