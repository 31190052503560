import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import firebase from './Firebase';
import Notes from './Notes';
import SideBar from './SideBar';

const mapStateToProps = state => {
    return {
        username: state.username, avatarUrl: state.avatarUrl, bio: state.bio, id: state.id, joinDate: state.joinDate,
        isDarkMode: state.isDarkMode
    };
};

class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notesList: [],
        };

        document.title = "تقارير";

        firebase.database().ref('notes/')
            .orderByChild('noteType')
            .equalTo(global.config.NoteTypes.Report)
            .once('value')
            .then((snapshot) => {
                if (snapshot.val()) {
                    if (snapshot.val() != null) {
                        let arrayOfKeys = Object.keys(snapshot.val())
                            .sort()
                            .reverse();

                        let results = arrayOfKeys.map((key) => snapshot.val()[key]);

                        for (let i = 0; i < results.length; ++i) {
                            results[i].key = results[i].key = arrayOfKeys[i];
                        }

                        this.setState({ notesList: results });
                    }
                }
            })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={3}>
                        <div className="do-not-show-in-mobile">
                            <SideBar />
                        </div>
                    </Col>

                    <Col className="text-align-right" xs={12} md={9}>
                        <div className={`mb-3 element-light-mode ${this.props.isDarkMode ? "element-dark-mode" : ""}`}>
                            <a href="/">الرئيسية</a>
                            &nbsp;
                            |
                            &nbsp;
                            <a>
                                تقارير
                            </a>
                        </div>
                        <Notes notesList={this.state.notesList} isShowType={false} auth={this.props.auth} />
                    </Col>
                </Row>
            </div >
        )
    }
}

export default connect(mapStateToProps)(Reports);