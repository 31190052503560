import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import ProfileCard from './ProfileCard';

const mapStateToProps = state => {
    return { username: state.username, avatarUrl: state.avatarUrl, bio: state.bio, id: state.id, joinDate: state.joinDate };
};

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Nothing
        };
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                    </Col>
                    <Col xs={12} md={4}>
                        <ProfileCard />
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect(mapStateToProps)(Profile);