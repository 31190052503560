import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Button, Spinner, Card, Figure, Carousel, Image } from 'react-bootstrap';
import firebase from './Firebase';
import Notes from './Notes';
import SideBar from './SideBar';


const mapStateToProps = state => {
    return { username: state.username, isDarkMode: state.isDarkMode };
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notesList: [],
            lastKey: "",
            arrayOfKeys: [],
            isShowMore: 1,
            isLoading: false,
        };

        this.fetchMorePosts = this.fetchMorePosts.bind(this);

        document.title = "الرئيسيّة - Soumar Tech Park";
    }

    componentDidMount() {
        this.setState({ isLoading: true });

        firebase.database().ref('notes/')
            .orderByKey()
            .limitToLast(10)
            .once('value')
            .then((snapshot) => {
                if (snapshot.val() != null) {
                    this.setState({
                        arrayOfKeys: Object.keys(snapshot.val())
                            .sort()
                            .reverse()
                    });

                    let results = this.state.arrayOfKeys.map((key) => snapshot.val()[key]);

                    for (let i = 0; i < results.length; ++i) {
                        results[i].key = this.state.arrayOfKeys[i];
                    }

                    this.setState({ isLoading: false, notesList: results, lastKey: this.state.arrayOfKeys[this.state.arrayOfKeys.length - 1] });

                } else {
                    this.setState({ isShowMore: 0, isLoading: false });
                }
            }).catch((error) => {
                console.log(JSON.stringify(error));
            });
    }

    fetchMorePosts(lastKey) {
        this.setState({ isLoading: true });

        firebase.database().ref('notes/')
            .orderByKey()
            .endBefore(lastKey)
            .limitToLast(6)
            .once('value')
            .then((snapshot) => {
                if (snapshot.val() != null) {
                    let arrayOfKeys = Object.keys(snapshot.val())
                        .sort()
                        .reverse();

                    let results = arrayOfKeys.map((key) => snapshot.val()[key]);

                    for (let i = 0; i < results.length; ++i) {
                        results[i].key = arrayOfKeys[i];
                    }

                    this.setState({ arrayOfKeys: this.state.arrayOfKeys.concat(arrayOfKeys) });
                    this.setState({ isLoading: false, notesList: this.state.notesList.concat(results), lastKey: this.state.arrayOfKeys[this.state.arrayOfKeys.length - 1] });
                } else {
                    this.setState({ isShowMore: 0, isLoading: false });
                }
            }).catch((error) => {
                console.log(JSON.stringify(error));
            });
    }

    render() {
        var notesList = <Notes notesList={this.state.notesList} auth={this.props.auth} isShowType={true} />;

        return (
            <div>

                <Row>
                    <Col md={3}>
                        <div className="do-not-show-in-mobile">
                            <SideBar />
                        </div>
                    </Col>
                    <Col className="text-align-right" xs={12} md={9}>
                        <div>
                            {notesList}
                            {!this.state.isLoading &&
                                <div style={{ textAlign: "center" }}>
                                    <Button variant="outline-secondary"
                                        onClick={() => { this.fetchMorePosts(this.state.lastKey) }}>
                                        عرض المزيد
                                    </Button>
                                </div>
                            }

                            {this.state.isLoading &&
                                (<div style={{ textAlign: "center" }}>
                                    <Spinner animation="border"
                                        className={`spinner-style ${this.props.isDarkMode ? "spinner-style-dark-mode" : ""}`} size="sm" />
                                </div>)
                            }
                        </div>
                    </Col >
                </Row >
            </div >
        )
    }
}

export default connect(mapStateToProps)(Home);