import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';
import 'firebase/auth';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyBBTI5ip3K9buEaa3qxZiecBsAk5qvJRGo",
    authDomain: "soumarprojet.firebaseapp.com",
    databaseURL: "https://soumarprojet.firebaseio.com",
    projectId: "soumarprojet",
    storageBucket: "soumarprojet.appspot.com",
    messagingSenderId: "675023253971"
};
firebase.initializeApp(config);

export default firebase;