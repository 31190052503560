import React, { Component } from 'react';
import firebase from 'firebase/app';
import { Modal, FormControl, InputGroup, Image, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        isDarkMode: state.isDarkMode
    };
};

function mapDispatchToProps(dispatch) {
    return {
    };
}

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            searchResults: [],
            isLoading: false
        };
        this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
    }

    getLinkByNoteType(title, noteType) {
        let res = "";
        switch (noteType) {
            case global.config.NoteTypes.Review:
                res = `/reviews/${title.replace(/\s+/g, '-')}`;
            case global.config.NoteTypes.Report:
                res = `/reports/${title.replace(/\s+/g, '-')}`;
            case global.config.NoteTypes.News:
                res = `/news/${title.replace(/\s+/g, '-')}`;
        }

        window.location.href = res;
    }

    handleCloseSearchModal = () => {
        let self = this;
        this.setState({ searchTerm: '', searchResults: [] }, () => {
            self.props.handleCloseSearchModal();
        });
    }

    handleSearch = () => {
        this.setState({ isLoading: true })
        const { searchTerm } = this.state;
        if (searchTerm.trim() === '') {
            this.setState({ searchResults: [] });
            return;
        }

        // Reference to the 'notes' node
        const notesRef = firebase.database().ref('notes');

        notesRef.once('value').then((snapshot) => {
            if (snapshot.val() !== null) {
                // Convert the search term to lowercase
                const searchTermLowerCase = searchTerm.toLowerCase();

                // Filter notes by their title (case-insensitive)
                const searchResults = Object.values(snapshot.val()).filter((note) =>
                    note.title.toLowerCase().includes(searchTermLowerCase)
                );
                this.setState({ isLoading: false })
                this.setState({ searchResults });
            } else {
                this.setState({ isLoading: false })
                this.setState({ searchResults: [] });
            }
        });
    };

    handleInputChange = (e) => {
        this.setState({ searchTerm: e.target.value }, this.handleSearch);
    };

    render() {
        const { searchTerm, searchResults, isLoading } = this.state;

        return (
            <Modal size="md" show={this.props.showSearchModal} onHide={this.handleCloseSearchModal} className={this.props.showSearchModal ? "blur-page" : ""}>
                <div>
                    <Modal.Header closeButton className={`modal-container ${this.props.isDarkMode ? "search-style-dark-mode" : ""}`}>
                    </Modal.Header>
                    <Modal.Body className={`modal-container ${this.props.isDarkMode ? "search-style-dark-mode" : ""}`}>
                        <div style={{ textAlign: "center" }}>
                            <InputGroup>
                                <FormControl type="text" placeholder="ابحث في الموقع" value={searchTerm}
                                    className="searchInput"
                                    onChange={this.handleInputChange} />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1"><i class="bi bi-search"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                            <div className='mt-3 search-result-content' style={{ textAlign: "right" }}>
                                {isLoading && searchTerm != "" ?
                                    <div class="spinner-grow text-dark" role="status">
                                    </div> :
                                    searchResults.map((note, index) => (
                                        <div key={index}>
                                            <div class="d-flex flex-row mb-3">
                                                <div class="p-2"><Image src={note.introUrl} rounded className='search-result-image do-not-show-in-mobile' /></div>
                                                <div class="p-.2">
                                                    <Link
                                                        onClick={() => this.getLinkByNoteType(note.title, note.noteType)}
                                                        className={`${this.props.isDarkMode ? "title-style-dark-mode" : "title-style"}`}>
                                                        <b>{note.title}</b>
                                                    </Link>
                                                    <br />
                                                    <span className='mt-2 text-muted' style={{ fontSize: "small" }}>{note.noteDate}</span></div>
                                            </div>
                                            <hr />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Modal.Body>

                </div >
            </Modal >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);